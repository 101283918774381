//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
export default {
  props: ["pageName", "isLogin"],
  data(){
    return {
      inMail: "", // 未读站内信数量
    }
  },
  computed:{
    menuDataRes () {
      const userInfo = this.$store.state.user;
      /** 角色配置菜单
       * 1： 商标查询   2：商品/服务分类  3：商标对比   4：商标分析  5：商标公告
       * 6：裁定文书    8：商标监控   10：图形查询  11：业务线索    12：品牌风险
       * 13：专项查询   14：AI近似分析
       * */
      /*
      * uc_vip_level: 1 青春版  2专业版  11员工 21免费试用  31公司客户
      * uc_memberType: 1代理人  2申请人
      * */
      let data = [];
      if(userInfo){
        if(userInfo.uc_memberType === 1 || userInfo.uc_memberType === 3) { // 代理人
          data = this.tools.menuData.filter( item => item.index !== '12');
        } else if(userInfo.uc_memberType === 2 || userInfo.uc_memberType === 4){
          data = this.tools.menuData.filter( item => item.index !== '11');
        } else {
          data = this.tools.menuData;
        }
      }else{
        data = this.tools.menuData;
      }
      /*if(userInfo && userInfo.uc_vip_level == 1){  // 青春版  [1,2,3,5,6]
        let hasMenus = ['1','2','3','5','6']
        data = this.tools.menuData.filter(item => hasMenus.indexOf(item.index) > -1)
      } else if(userInfo && userInfo.uc_vip_level === 21) { // 免费试用
        data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '8');
      } else { // 其他版本 -- 菜单都显示（只限制次数）
        data = this.tools.menuData.filter( item => item.index !== '9');
        if(userInfo && userInfo.uc_memberType === 1) { // 代理人
          data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '12');
        }
        if(userInfo && userInfo.uc_memberType === 2) {// 申请人
          data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '11');
        }
        if(userInfo && userInfo.uc_memberType === 4) { // 其他
          data = this.tools.menuData.filter( item => item.index !== '11');
        }
      }*/
      return data
    },
    activeMenu(){
      return this.$route.path
    }

  },
  watch:{
    isLogin(newVal){
      if(newVal) this.getNoticeNumber()
    }
  },
  async mounted() {

    this.getNoticeNumber()
  },
  methods: {
    // 开通会员
    goVersionIntroduction(){
      this.$router.push({
        path:"/user/VersionIntroduction",
      })
    },
    // 关于我们
    goAboutWe(){
      this.$router.push({
        path:"/user/About",
      })
    },
    goRoute(vc){
      if(vc.index === '/trademarkSearch'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInto", true)
      }
      if(vc.index === '/trademarkSearchInternational'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInterInto", true)
      }
      this.$router.push(vc.index)
    },
    getNoticeNumber(){
      if(!this.isLogin) return
      this.$axios.get("/api/Member/getNoticeNumber", {
        params: { id: Math.random() }
      }).then(res => {
        let _data = res.data.data;
        if (_data) {
          this.inMail = _data.inMail;
        }
      })
    }
  }

};
